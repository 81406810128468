import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from 'react-toastify';
// package css
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-18-image-lightbox/style.css";
// internal css
import "./assets/css/app.css";
import "./assets/css/custom.css";
import "./assets/css/table.css";
import "./assets/css/filter-popup.css";
import "./assets/css/form.css";
import "./assets/css/university.css";
import "./assets/css/course.css";
import "./assets/css/student.css";
import "./assets/css/modal.css";
import "./assets/css/login.css";
import "./assets/css/university-info.css";
import "./assets/css/view-info.css";
import "./assets/css/app-status.css";

// Routers
import App from "./App";

// render
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Fragment>
    <App />
    <ToastContainer/>
  </Fragment>
);
console.log("Version 4.2");