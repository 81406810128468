// package
import { Fragment, useEffect, useState, useRef } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Modal, DatePicker, TimePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

// includes
import FieldSelect from "elements/fieldSelect";
import { api, common, config } from "helpers";
import StatusBar from "elements/statusBar";

function ApplicationProcess(props) {
  // state
  const formRef = useRef(null);
  const handleClick = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [interviewIdx, setInterviewIdx] = useState(0);
  const [overflow] = useState(true);

  const [id] = useState(props.id);
  const [init, setInit] = useState(0);
  const [status, setStatus] = useState("process");
  const [result, setResult] = useState({ application: props.result });
  const [files, setFiles] = useState([]);
  const [section, setSection] = useState([
    {
      title: "",
      description: "",
      category: "",
      status: "progress",
      mail: false,
      doc: [],
      interview: [],
      iStatus: { value: "Scheduled", label: "Scheduled" },
      iDate: null,
      reason: "",
      courses: [
        {
          universityName: "",
          universityId: "",
          courseName: "",
          courseId: "",
        },
        {
          universityName: "",
          universityId: "",
          courseName: "",
          courseId: "",
        },
      ],
      fields: [{ field: "", description: "", status: "" }],
      intake: "",
    },
  ]);
  const [intake, setIntake] = useState([]);

  // effect
  useEffect(() => {
    if (!init) {
      // reset edit category section
      setIsEdit(false);
      setFiles([]);
      setSection([initSection()]);

      setStatus("process");
      initData();
    } else if (init >= 1) {
      setStatus("success");
    }
  }, [init]);

  // init
  const initData = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    const courseIntake = (props.result?.course?.intake || []).flatMap((obj) =>
      (obj.month || [])
        .filter(
          (mObj) =>
            obj.year > currentYear ||
            (obj.year === currentYear && mObj >= currentMonth)
        ) // Filter out past dates
        .map((mObj) => ({
          label: `${obj.year} ${common.getMonthList()[mObj - 1].label}`,
          value: `${obj.year} ${mObj}`,
        }))
    );
    let filtered = courseIntake.filter(
      (obj) =>
        obj.value != `${result.application.year} ${result.application.month}`
    );

    setIntake(filtered);

    getTimeline();
  };

  const getTimeline = () => {
    let data = {
      url: "application_status",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setResult((prev) => ({ ...prev, status: res.data }));
        setInit((prev) => ++prev);
      } else {
        setStatus("error");
      }
    });
  };

  const saveAppProcess = async () => {
    let payload = [];

    for (const x of section) {
      x.category = x.category.value;
      x.iStatus = x.iStatus?.value || null;
      x.mail = Boolean(x.mail);

      if (x.category === "missing_information") {
        x.status = "completed";
        x.fields.forEach((f) => {
          f.field = f.field.value;
          f.status = f.status.value;
          if (f.status !== "reviewed") {
            x.status = "progress";
          }
        });
      } else if (x.category === "course_suggestion") {
        x.courses.forEach((c) => {
          c.courseName = c.courseId.label;
          c.courseId = c.courseId.value;
          c.universityName = c.universityId.label;
          c.universityId = c.universityId.value;
        });
      } else if (x.category === "interview") {
        if (
          ["Attended", "Not Attended", "Rescheduled", "Cancelled"].includes(
            x.iStatus
          )
        ) {
          x.status = "completed";
        }
      } else if (x.category === "intake_differ") {
        x.intake = x.intake.value;
        x.status = "completed";
      } else if (x.category === "review") {
        x.status = x.review === true ? "completed" : "process";
      } else if (x.category === "document_upload") {
        const upf = await uploadFile(files);
        x.doc = upf?.data?.link;
        x.status = "completed";
      } else if (["enrolled", "rejected", "update"].includes(x.category)) {
        x.status = "completed";
      } else if (x.category === "cancelled") {
        x.status = x.accepted === true ? "completed" : "process";
      }

      if (x.category !== "missing_information") {
        x.fields = [];
      }

      payload.push(x);
    }

    console.log("payload", payload);

    let data = {
      url: "application",
      method: "PUT",
      query: `/${id}`,
      body: payload,
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        setSection([initSection()]);
        setFiles([]);
        setInit(0);
        setIsEdit(false);
      } else {
        //setStatus("error");
      }
    });
  };

  const uploadFile = async (arr) => {
    var formdata = new FormData();
    arr.map((i) => {
      formdata.append("file", i);
    });
    let data = {
      url: "common_upload",
      method: "POST",
      body: formdata,
      ssr: true,
      cType: 6,
    };
    let resultData = await api.call(data);
    return resultData;
  };

  const tlData = (type, statusType = "") => {
    let tl = type;
    let res = {
      status: tl?.status,
      lineClass: "tl-status-none",
      headClass: "tmh-grey",
      dateAt: tlDate(tl?.createdAt),
    };

    if (
      [
        "upload",
        "progress",
        "applied",
        "pending",
        "invalid",
        "missing",
        "schedule",
      ].includes(tl?.status)
    ) {
      res.headClass = "tmh-yellow";
    } else if (
      ["rejected", "intake_diff", "notEligible", "cancelled"].includes(
        tl?.status
      )
    ) {
      res.headClass = "tmh-red";
    } else if (["completed", "approved", "accepted"].includes(tl?.status)) {
      res.lineClass = "tl-status-complete";
      if (["Cancellation", "Rejected"].includes(statusType)) {
        res.headClass = "tmh-red";
      } else {
        res.headClass = "tmh-green";
      }
    }
    return res;
  };

  const tlDate = (dt, type = "date") => {
    let result = dt
      ? moment(dt).format("MMM Do YYYY") + " | " + moment(dt).format("hh:mm A")
      : "";
    if (type === "timeSince") {
      return dt ? common.timeSince(new Date(dt)) : "";
    } else {
      return result;
    }
  };

  const editSection = (e, nData) => {
    let rData = common.reParse(nData);
    e.preventDefault();
    rData.category = config.statusCategory.filter(
      (obj) => obj.value == rData.category
    )[0];

    if (rData.category?.value == "missing_information") {
      rData.fields.map((field) => {
        if (field.field) {
          field.field = config.missingFields.filter(
            (obj) => obj.value === field.field
          )[0];
        }
        if (field.status) {
          field.status = config.missingStatus.filter(
            (obj) => obj.value === field.status
          )[0];
        }
      });
    } else if (rData.category?.value == "course_suggestion") {
      rData.courses.map((course) => {
        course.courseId = { label: course.courseName, value: course.courseId };
        course.universityId = {
          label: course.universityName,
          value: course.universityId,
        };
      });
    } else if (rData.category?.value == "interview") {
      rData.iStatus = { label: rData.iStatus, value: rData.iStatus };
    } else if (rData.category?.value == "intake_differ") {
      let intakeMonthYear = rData.intake.split(" ");
      rData.intake = {
        label: `${intakeMonthYear[0]} ${
          common.getMonthList()[intakeMonthYear[1] - 1].label
        }`,
        value: `${intakeMonthYear[0]} ${intakeMonthYear[1]}`,
      };
    } else if (rData.category?.value == "review") {
      rData.review = rData.status === "progess" ? false : true;
    }
    setSection([rData]);
    //ToDo : scroll to botton of the page
  };

  const addSection = (e, type, pIdx) => {
    e.preventDefault();
    let sData = common.reParse(section);
    if (type == "section") {
      setSection((pData) => {
        sData.push(initSection());
        return sData;
      });
    } else if (type == "field") {
      let uRow = sData[pIdx];
      uRow.fields.push({ field: "", description: "", status: "" });
      const updatedData = sData.map((x, i) => (pIdx === i ? uRow : x));
      setSection(updatedData);
    }
  };

  const removeSection = (e, pIdx, cIdx, type) => {
    e.preventDefault();
    let sData = common.reParse(section);
    if (type == "section") {
      const updatedData = sData.filter((x, i) => i !== pIdx);
      setSection(updatedData);
    } else if (type == "field") {
      let uRow = sData[pIdx];
      const updatedData = uRow.fields.filter((x, i) => i !== cIdx);
      sData[pIdx].fields = updatedData;
      setSection(sData);
    }
  };

  const resetSection = (e) => {
    e.preventDefault();
    setIsEdit(false);
    setFiles([]);
    setSection([initSection()]);
  };

  const addDate = (isDate, date, rIdx = null) => {
    let sData = common.reParse(section);
    if (isDate) {
      let formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
      let allDates = sData[interviewIdx].interview.map((item) => item.date);
      if (allDates.includes(formattedDate)) {
        common.notify("E", "Already added.Please select another date");
      } else {
        sData[interviewIdx].interview.push({
          date: moment(new Date(date)).format("YYYY-MM-DD"),
          slot: [],
        });
        setSection(sData);
      }
    } else {
      let iDate = new Date(sData[interviewIdx].interview[rIdx].date);
      let fDate = moment(new Date(date));
      fDate.set("month", iDate.getMonth());
      fDate.set("year", iDate.getFullYear());
      fDate.set("date", iDate.getDay());

      if (
        sData[interviewIdx].interview[rIdx].slot.includes(
          fDate.format("YYYY-MM-DDThh:mm:ssZ")
        )
      ) {
        common.notify("E", "Already added.Please select another time");
      } else {
        sData[interviewIdx].interview[rIdx].slot.push(
          fDate.format("YYYY-MM-DDThh:mm:ssZ")
        );
        setSection(sData);
      }
    }
  };
  const deleteDate = (e, rowIdx, colIdx = null) => {
    e.preventDefault();
    if (colIdx != null) {
      let sData = common.reParse(section);
      let slots = sData[interviewIdx].interview[rowIdx].slot.filter(
        (_, index) => index !== colIdx
      );
      sData[interviewIdx].interview[rowIdx].slot = slots;
      setSection(sData);
    } else {
      let sData = common.reParse(section);
      let interview = sData[interviewIdx].interview.filter(
        (_, index) => index !== rowIdx
      );
      sData[interviewIdx].interview = interview;
      setSection(sData);
    }
  };

  const deleteFile = (e, idx) => {
    e.preventDefault();
    let uFiles = files.filter((x, i) => i !== idx);
    setFiles(uFiles);
  };

  const onHandleChange = (e, type, key, childKey = null, pIdx, cIdx = null) => {
    let value =
      type === "select"
        ? e
        : type === "text"
        ? e.target.value
        : type === "check"
        ? e.target.checked
        : null;
    let sData = common.reParse(section);
    if (!childKey) {
      const updatedData = sData.map((x, i) =>
        pIdx === i ? { ...x, [key]: value } : x
      );
      setSection(updatedData);
    } else {
      let uRow = sData[pIdx];
      uRow[key][cIdx][childKey] = value;
      const updatedData = sData.map((x, i) => (pIdx === i ? uRow : x));
      setSection(updatedData);
    }
  };

  const initSection = () => {
    return {
      title: "",
      description: "",
      category: "",
      status: "progress",
      mail: false,
      doc: [],
      interview: [],
      iStatus: { value: "Scheduled", label: "Scheduled" },
      iDate: null,
      reason: "",
      courses: [
        {
          universityName: "",
          universityId: "",
          courseName: "",
          courseId: "",
        },
        {
          universityName: "",
          universityId: "",
          courseName: "",
          courseId: "",
        },
      ],
      fields: [{ field: "", description: "", status: "" }],
      intake: "",
    };
  };

  const saveSection = (e) => {
    e.preventDefault();
    let secValid = validField();
    if (secValid?.length === secValid.filter((val) => val === true)?.length) {
      saveAppProcess();
    } else {
      common.notify("E", "Please fill all the field values");
    }
  };

  const validField = () => {
    let valid = [];
    section.map(async (obj) => {
      let mainValid = false;
      let categoryValid = false;

      if (obj.category && obj.title && obj.description) {
        mainValid = true;
      }
      console.log("obj.interview", obj.category.value, obj);
      //category wise validation
      if (
        ["document_request", "course_payment", "enrolled", "rejected"].includes(
          obj.category?.value
        )
      ) {
        categoryValid = true;
      } else if (
        obj.category.value == "course_suggestion" &&
        obj.courses.length > 0
      ) {
        let c1Array = Object.values(obj.courses[0].courseId);
        let c2Array = Object.values(obj.courses[1].courseId);
        if (!c1Array.includes("") && !c2Array.includes("")) {
          categoryValid = true;
        }
      } else if (obj.category.value == "missing_information") {
        categoryValid = true;
        obj.fields.map((field) => {
          if (
            !(field.field?.value && field.description && field.status?.value)
          ) {
            categoryValid = false;
          }
        });
      } else if (
        obj.category.value == "interview" &&
        obj.iStatus?.value &&
        obj.interview?.length > 0
      ) {
        categoryValid = true;
      } else if (obj.category.value == "document_upload" && files?.length > 0) {
        categoryValid = true;
      } else if (obj.category.value == "intake_differ" && obj.intake?.value) {
        categoryValid = true;
      } else if (
        [
          "cancelled",
          "rejected",
          "review",
          "document_upload",
          "update",
        ].includes(obj.category?.value)
      ) {
        categoryValid = true;
      }

      if (!mainValid || !categoryValid) {
        valid.push(false);
      } else if (mainValid && categoryValid) {
        valid.push(true);
      }
    });
    return valid;
  };

  return (
    <div>
      {status !== "success" ? (
        <StatusBar status={status} />
      ) : (
        <Fragment>
          <Card className="tm-card app-status-card mb-4">
            <Card.Body>
              <button className="btn-refresh">
                <RefreshIcon
                  onClick={(e) => {
                    e.preventDefault();
                    setInit(null);
                  }}
                />
              </button>
              {result.status &&
                result.status.map((rData, k) => {
                  let td = tlData(rData);
                  return (
                    <div
                      className="timeline-row position-relative mb-2 tl-status-complete"
                      key={k}
                    >
                      <div className="tab-head-wrap d-flex">
                        <div
                          className={`ststus-title clr-white ${
                            td.status?.toLowerCase() === "completed"
                              ? "tmh-green"
                              : "tmh-yellow"
                          }`}
                        >
                          {rData?.title || "No Title"}
                        </div>
                      </div>
                      <div className="tab-content-wrap">
                        <div className="row align-items-center">
                          <div className="col-md-3 timeline-col timeline-status-info text-center">
                            {td.status === "completed" ? (
                              <h5 className="tmt-green">Completed</h5>
                            ) : (
                              <h5 className="tmt-yellow">In Progress</h5>
                            )}
                            <p className="tm-date mb-0">{td.dateAt}</p>
                          </div>

                          <div className="col-md-6 timeline-col timeline-status-content">
                            <h5>Info</h5>
                            <p>{rData.description}</p>
                            {rData?.iStatus?.value?.toLowerCase() ===
                              "confirmed" &&
                              rData?.iDate && (
                                <p>
                                  <b>
                                    Student booked the slot @{" "}
                                    {moment(new Date(rData?.iDate)).format(
                                      "YYYY-MM-DD hh:mm A"
                                    )}{" "}
                                  </b>
                                </p>
                              )}
                            {rData.reason && (
                              <p>
                                <b>Reason:</b> {rData.reason}
                              </p>
                            )}
                            {rData.intake && (
                              <p>
                                <h5>New Intake</h5>
                                {
                                  common.getMonthList()[
                                    rData.intake.split(" ")[1] - 1
                                  ].label
                                }{" "}
                                {rData.intake.split(" ")[0]}
                              </p>
                            )}
                            <div className="d-flex flex-wrap align-items-center gap-3 row-gap-0">
                              {["document_upload", "document_request"].includes(
                                rData?.category
                              ) &&
                                rData?.doc.map((obj, idx) => {
                                  return (
                                    <div>
                                      <img
                                        src={common.loadImg("attach-icn.svg")}
                                        alt="attach icon"
                                        style={{
                                          width: "10px",
                                          marginRight: "4px",
                                        }}
                                      />
                                      <Link
                                        key={idx}
                                        to="#"
                                        onClick={() => common.openCloudUrl(obj)}
                                      >
                                        {common.fileName(obj)}
                                      </Link>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                          <div className="col-md-3 timeline-col d-flex flex-row align-items-center justify-content-between">
                            {rData?.category === "interview" && (
                              <div>
                                <h5>Status</h5>
                                <p className="appst-txt">{rData?.iStatus}</p>
                              </div>
                            )}
                            {![
                              "fees_payment",
                              "document_request",
                              "intake_differ",
                            ].includes(rData?.category) &&
                              rData?.status === "progress" && (
                                <div className="condition-edit-icon">
                                  <Link
                                    to="#"
                                    onClick={(e) => {
                                      editSection(e, rData);
                                      setIsEdit(true);
                                      setInterviewIdx(
                                        rData?.category === "interview" ? k : 0
                                      );
                                      handleClick();
                                    }}
                                    className="ms-3"
                                  >
                                    <img
                                      src={common.loadImg("edit-btn.svg")}
                                      alt="edit icon"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  </Link>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      {/* Next row */}
                      {[
                        "missing_information",
                        "interview",
                        "course_suggestion",
                      ].includes(rData?.category) && (
                        <div className="tab-content-wrap pb-2">
                          {rData?.category === "missing_information" && (
                            <Fragment>
                              <div className="row justify-content-between align-items-center">
                                <div className="col-md-3 timeline-col timeline-status-info py-1">
                                  <h5>Fields</h5>
                                </div>
                                <div className="col-md-6 timeline-col timeline-status-info py-1">
                                  <h5>Description</h5>
                                </div>
                                <div className="col-md-3 timeline-col timeline-status-info py-1">
                                  <h5>Status</h5>
                                </div>
                              </div>
                              {rData.fields.map((ci, ck) => (
                                <div
                                  className="row justify-content-between align-items-center"
                                  key={ck}
                                >
                                  <div className="col-md-3 timeline-col timeline-status-info py-1">
                                    <p className="status-info">{ci.field}</p>
                                  </div>
                                  <div className="col-md-6 timeline-col timeline-status-info py-1">
                                    <p className="status-info">
                                      {ci.description}
                                    </p>
                                  </div>
                                  <div className="col-md-3 timeline-col timeline-status-info py-1">
                                    <p className="appst-txt">
                                      {common.capitalize(ci.status)}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </Fragment>
                          )}
                          {rData?.category === "interview" && (
                            <Fragment>
                              <div className="row justify-content-between align-items-center">
                                <div className="col-md-3 timeline-col timeline-status-info py-1"></div>
                                <div className="col-md-6 timeline-col timeline-status-info py-1">
                                  <h5>Interview Dates</h5>
                                  <div>
                                    {rData?.interview?.map((item, index) => (
                                      <div className="interview-datetime-col">
                                        <p className="interview-date">
                                          {item?.date}
                                        </p>
                                        {item?.slot?.map((slotDate, i) => (
                                          <span
                                            className="interview-time"
                                            key={index}
                                          >
                                            {moment(new Date(slotDate)).format(
                                              "hh:mm A"
                                            )}
                                          </span>
                                        ))}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className="col-md-3 timeline-col timeline-status-info py-1">
                                  {rData?.iDate && (
                                    // <p className="status-info">
                                    //   <p>Confirmed Date</p>
                                    // {moment(new Date(rData?.iDate)).format(
                                    //   "YYYY-MM-DD hh:mm A"
                                    // )}
                                    // </p>
                                    <div>
                                      <h5>Confirmed Date</h5>
                                      <p>
                                        {moment(new Date(rData?.iDate)).format(
                                          "YYYY-MM-DD hh:mm A"
                                        )}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          )}
                          {rData?.category === "course_suggestion" &&
                            rData?.status === "progress" && (
                              // <Fragment>
                              //   <div className="row">
                              //     <div className="col-md-3"></div>
                              //     <div className="col-md-3 timeline-col">
                              //       <h5>Suggested course 1</h5>
                              //       <p>
                              //         {rData?.courses[0]?.courseName},{" "}
                              //         {rData?.courses[0]?.universityName}
                              //       </p>
                              //     </div>
                              //     <div className="col-md-3"></div>
                              //   </div>
                              //   <div className="row">
                              //     <div className="col-md-3"></div>
                              //     <div className="col-md-3 timeline-col">
                              //       <h5>Suggested course 2</h5>
                              //       <p>
                              //         {rData?.courses[1]?.courseName},{" "}
                              //         {rData?.courses[1]?.universityName}
                              //       </p>
                              //     </div>
                              //     <div className="col-md-3"></div>
                              //   </div>
                              // </Fragment>
                              <Fragment>
                                <div className="row">
                                  <div className="col-md-3"></div>
                                  <div className="col-md-3 timeline-col pt-0">
                                    <h5>Suggested course 1</h5>
                                    <p>
                                      {rData?.courses[0]?.courseName},{" "}
                                      {rData?.courses[0]?.universityName}
                                    </p>
                                  </div>
                                  <div className="col-md-3 timeline-col pt-0">
                                    <h5>Suggested course 2</h5>
                                    <p>
                                      {rData?.courses[1]?.courseName},{" "}
                                      {rData?.courses[1]?.universityName}
                                    </p>
                                  </div>
                                </div>
                              </Fragment>
                            )}
                        </div>
                      )}
                    </div>
                  );
                })}
            </Card.Body>
          </Card>
          <Card ref={formRef}>
            <Card.Body>
              {section &&
                section.map((obj, k) => {
                  return (
                    <Fragment key={"sec_" + k}>
                      {obj?.category?.value && (
                        <div className="tab-head-wrap d-flex">
                          <div className={`ststus-title clr-white tmh-yellow`}>
                            {obj?.category?.label}
                          </div>
                        </div>
                      )}
                      <div className="row app-statusform-wrap">
                        <div className="col-3 mb-2 mb-2">
                          <label>Category</label>
                          <Select
                            className="appstatus-select"
                            classNamePrefix="apst-react-select"
                            value={obj.category}
                            isDisabled={obj?._id}
                            options={config.statusCategory}
                            isOptionDisabled={(option) => option.disabled}
                            isClearable={true}
                            onChange={(e) =>
                              onHandleChange(e, "select", "category", null, k)
                            }
                          />
                        </div>
                        <div className="col-3 mb-2">
                          <label>Title</label>
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter title"
                            className="form-control"
                            maxLength={60}
                            value={obj.title}
                            onChange={(e) =>
                              onHandleChange(e, "text", "title", null, k)
                            }
                          />
                        </div>
                        <div className="col-3 mb-2">
                          {" "}
                          <label>Description</label>
                          <textarea
                            rows={5}
                            className="form-control"
                            placeholder="Enter description"
                            maxLength={5000}
                            value={obj.description}
                            onChange={(e) =>
                              onHandleChange(e, "text", "description", null, k)
                            }
                          />
                        </div>

                        <div className="col-3 mb-2">
                          <label>Send Email</label>
                          <br />
                          <label className="role-checkbox-wrap mt-2 ms-4">
                            <input
                              type="checkbox"
                              name="mail"
                              value={obj.mail}
                              onChange={(e) =>
                                onHandleChange(e, "check", "mail", null, k)
                              }
                            />
                            <span className="checkmark"></span>
                          </label>
                          {k > 0 && (
                            <div className="d-flex align-items-center justify-content-end">
                              <Link
                                to="#"
                                onClick={(e) =>
                                  removeSection(e, k, 0, "section")
                                }
                              >
                                <img
                                  src={common.loadImg("delete-icn.svg")}
                                  alt="delete icon"
                                  title="Delete Category"
                                  style={{ width: "16px", height: "16px" }}
                                />
                              </Link>
                            </div>
                          )}
                        </div>
                        {obj.category?.value === "review" && (
                          <Fragment>
                            <div className="row">
                              <div className="col-3 mb-2">
                                <label>Reviewed?</label>
                                <br />
                                <label className="role-checkbox-wrap mt-2 ms-4">
                                  <input
                                    type="checkbox"
                                    name="review"
                                    value={obj.review}
                                    onChange={(e) =>
                                      onHandleChange(
                                        e,
                                        "check",
                                        "review",
                                        null,
                                        k
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </Fragment>
                        )}
                        {obj.category?.value === "cancelled" && (
                          <Fragment>
                            <div className="row">
                              <div className="col-3 mb-2">
                                <label>Accepted?</label>
                                <br />
                                <label className="role-checkbox-wrap mt-2 ms-4">
                                  <input
                                    type="checkbox"
                                    name="accepted"
                                    value={obj.accepted}
                                    onChange={(e) =>
                                      onHandleChange(
                                        e,
                                        "check",
                                        "accepted",
                                        null,
                                        k
                                      )
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </div>
                            </div>
                          </Fragment>
                        )}
                        {obj.category?.value === "course_suggestion" && (
                          <Fragment>
                            <div className="row">
                              <div className="col-3 mb-2">
                                <label>Suggested Univeristy 1</label>
                                <FieldSelect
                                  api="university"
                                  query="?offset=1&limit=1000"
                                  css={true}
                                  get="name"
                                  set="_id"
                                  value={obj?.courses[0]?.universityId || null}
                                  placeholder="Select university"
                                  render={"yes"}
                                  clearable={true}
                                  finder={false}
                                  multi={false}
                                  onSelect={(e) =>
                                    onHandleChange(
                                      e,
                                      "select",
                                      "courses",
                                      "universityId",
                                      k,
                                      0
                                    )
                                  }
                                />
                              </div>
                              {obj.courses[0].universityId?.value && (
                                <div className="col-3 mb-2">
                                  <label>Suggested Course 1</label>
                                  <FieldSelect
                                    api="course"
                                    query={
                                      obj.courses[0].universityId
                                        ? `?university=['${obj.courses[0].universityId?.value}']offset=1&limit=1000`
                                        : "?offset=1&limit=1000"
                                    }
                                    css={true}
                                    get="name"
                                    set="_id"
                                    value={obj?.courses[0]?.courseId || null}
                                    placeholder="Select course"
                                    render={"yes"}
                                    clearable={true}
                                    finder={false}
                                    multi={false}
                                    onSelect={(e) => {
                                      onHandleChange(
                                        e,
                                        "select",
                                        "courses",
                                        "courseId",
                                        k,
                                        0
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="row">
                              <div className="col-3 mb-2">
                                <label>Suggested Univeristy 2</label>
                                <FieldSelect
                                  api="university"
                                  query="?offset=1&limit=1000"
                                  css={true}
                                  get="name"
                                  set="_id"
                                  value={obj?.courses[1]?.universityId || null}
                                  placeholder="Select the university"
                                  render={"yes"}
                                  clearable={true}
                                  finder={false}
                                  multi={false}
                                  onSelect={(e) => {
                                    onHandleChange(
                                      e,
                                      "select",
                                      "courses",
                                      "universityId",
                                      k,
                                      1
                                    );
                                  }}
                                />
                              </div>
                              {obj.courses[1].universityId?.value && (
                                <div className="col-3 mb-2">
                                  <label>Suggested Course 2</label>
                                  <FieldSelect
                                    api="course"
                                    query={
                                      obj.courses[1].universityId
                                        ? `?query={"university":["${obj.courses[1]?.universityId?.value}"]}&offset=1&limit=1000`
                                        : "?offset=1&limit=1000"
                                    }
                                    css={true}
                                    get="name"
                                    set="_id"
                                    value={obj?.courses[1]?.courseId || null}
                                    placeholder="Select course"
                                    render={"yes"}
                                    clearable={true}
                                    finder={false}
                                    multi={false}
                                    onSelect={(e) =>
                                      onHandleChange(
                                        e,
                                        "select",
                                        "courses",
                                        "courseId",
                                        k,
                                        1
                                      )
                                    }
                                  />
                                </div>
                              )}
                            </div>
                          </Fragment>
                        )}

                        {obj.category?.value === "missing_information" &&
                          obj?.fields?.map((fObj, fIdx) => {
                            return (
                              <Fragment key={fIdx}>
                                <div className="col-3 mb-2">
                                  <label>Field</label>
                                  <Select
                                    className="appstatus-select"
                                    classNamePrefix="apst-react-select"
                                    isClearable={true}
                                    options={config.missingFields}
                                    onChange={(e) =>
                                      onHandleChange(
                                        e,
                                        "select",
                                        "fields",
                                        "field",
                                        k,
                                        fIdx
                                      )
                                    }
                                    value={fObj.field}
                                  />
                                </div>
                                <div className="col-3 mb-2">
                                  <label>Description</label>
                                  <textarea
                                    rows={5}
                                    className="form-control"
                                    placeholder="Enter description"
                                    onChange={(e) =>
                                      onHandleChange(
                                        e,
                                        "text",
                                        "fields",
                                        "description",
                                        k,
                                        fIdx
                                      )
                                    }
                                    value={fObj.description}
                                  />
                                </div>
                                <div className="col-md-3 mb-2">
                                  <label>Status</label>
                                  <div className="d-flex align-items-center gap-2">
                                    <div className="w-75">
                                      <Select
                                        className="appstatus-select"
                                        classNamePrefix="apst-react-select"
                                        isClearable={true}
                                        options={config.missingStatus}
                                        onChange={(e) =>
                                          onHandleChange(
                                            e,
                                            "select",
                                            "fields",
                                            "status",
                                            k,
                                            fIdx
                                          )
                                        }
                                        value={fObj.status}
                                      />
                                    </div>
                                    <div className="d-flex align-items-center gap-1">
                                      <button
                                        className="appst-inrdcr-btn incr-btn"
                                        onClick={(e) =>
                                          addSection(e, "field", k)
                                        }
                                      >
                                        +
                                      </button>
                                      {obj?.fields.length > 1 && (
                                        <button
                                          className="appst-inrdcr-btn dcr-btn"
                                          onClick={(e) =>
                                            removeSection(e, k, fIdx, "field")
                                          }
                                        >
                                          -
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3 mb-2"></div>
                              </Fragment>
                            );
                          })}
                        {obj.category?.value === "interview" && (
                          <Fragment>
                            <div className="col-3 mb-2">
                              <label>Interview Status</label>
                              <Select
                                className="appstatus-select"
                                classNamePrefix="apst-react-select"
                                options={config.interviewStatus}
                                isOptionDisabled={(option) => option.disabled}
                                value={obj.iStatus || null}
                                onChange={(e) =>
                                  onHandleChange(
                                    e,
                                    "select",
                                    "iStatus",
                                    null,
                                    k,
                                    0
                                  )
                                }
                              />
                            </div>
                            <div className="col-3 mb-2">
                              <label>Interview Slots</label>
                              <br />
                              <Link
                                to="#"
                                onClick={(e) => {
                                  setOpen(true);
                                  setInterviewIdx(k);
                                }}
                                className="me-5"
                              >
                                View
                              </Link>
                            </div>
                            {obj.reason && (
                              <div className="col-3 mb-2">
                                <label>Reason</label>
                                <br />
                                {obj.reason}
                              </div>
                            )}
                            {obj.iDate && (
                              <div className="col-3 mb-2">
                                <label>Confirmed Date</label>
                                <br />
                                {moment(new Date(obj?.iDate)).format(
                                  "YYYY-MM-DD hh:mm A"
                                )}
                              </div>
                            )}
                          </Fragment>
                        )}
                        {obj.category?.value === "intake_differ" && (
                          <Fragment>
                            <div className="col-3 mb-2">
                              <label>Intake Month & Year</label>
                              <Select
                                className="appstatus-select"
                                classNamePrefix="apst-react-select"
                                isClearable={true}
                                options={intake}
                                onChange={(e) =>
                                  onHandleChange(
                                    e,
                                    "select",
                                    "intake",
                                    null,
                                    k,
                                    0
                                  )
                                }
                                value={obj.intake || null}
                              />
                            </div>
                          </Fragment>
                        )}
                        {obj.category?.value === "document_upload" && (
                          <Fragment>
                            <div className="col-md-3 mb-2">
                              <label>File Upload</label>
                              <div className="file-upload-inp">
                                <input
                                  type="file"
                                  name="image"
                                  id="fileInput"
                                  className="form-control file-upload"
                                  multiple
                                  onChange={(e) => {
                                    setFiles(Array.from(e.target.files));
                                  }}
                                />
                              </div>
                              <div>
                                {files?.length > 0 &&
                                  files.map((file, idx) => (
                                    <div
                                      className="d-flex align-items-center justify-content-between pt-3"
                                      key={idx}
                                    >
                                      <p className="agt-proof-file appst-file-uploaded mb-0 et-cp">
                                        <Link to="#">{file.name}</Link>
                                      </p>
                                      <img
                                        src={common.loadImg(
                                          "iconly-dark-delete.svg"
                                        )}
                                        onClick={(e) => deleteFile(e, idx)}
                                        width={16}
                                        height={16}
                                        className="et-cp"
                                      />
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </Fragment>
                        )}
                      </div>
                    </Fragment>
                  );
                })}
              <div className="mt-4 d-flex align-items-center justify-content-between">
                <div>
                  {!isEdit && (
                    <button
                      className="appst-foot-btn add"
                      onClick={(e) => addSection(e, "section")}
                    >
                      Add New Category
                    </button>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-end gap-2">
                  <button
                    className="appst-foot-btn cancel"
                    onClick={(e) => resetSection(e)}
                  >
                    Clear
                  </button>
                  <button
                    className="appst-foot-btn save"
                    onClick={(e) => saveSection(e)}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Fragment>
      )}

      <Modal
        size="lg"
        overflow={overflow}
        open={open}
        onClose={(e) => setOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Interview Slots</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row p-0 m-0">
            {section[interviewIdx]?.interview?.map((obj, i) => {
              return (
                <div className="row px-0 m-0 py-2 date-slot-row" key={i}>
                  <div className="col-md-2">
                    <div className="date-slot-wrap">
                      <Link to="#" onClick={(e) => deleteDate(e, i)}>
                        <img
                          src={common.loadImg("delete-icn.svg")}
                          alt="delete icon"
                          style={{
                            width: "16px",
                            height: "16px",
                            marginRight: "10px",
                            marginTop: "-4px",
                          }}
                        />
                      </Link>
                      {obj.date}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="time-slot-wrap">
                      {obj.slot.map((slot, k) => {
                        return (
                          <div key={k} className="selected-time-slot">
                            {moment(new Date(slot)).format("hh:mm A")}
                            <Link to="#" onClick={(e) => deleteDate(e, i, k)}>
                              <span className="ms-2">&#10006;</span>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <TimePicker
                      className="rsuite-inputs"
                      size="sm"
                      format="hh:mm aa"
                      showMeridiem
                      placeholder="Select Time"
                      value=""
                      onOk={(date, e) => addDate(false, date, i)}
                    />
                  </div>
                </div>
              );
            })}

            <div className="col-md-4 py-2">
              <DatePicker
                className="rsuite-inputs"
                size="sm"
                format="dd-MM-yyyy"
                placeholder="Select Date"
                value=""
                onOk={(date, e) => addDate(true, date, null)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={(e) => setOpen(false)} appearance="subtle">
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default ApplicationProcess;
