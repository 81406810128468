export const config = {
  base_url: process.env.REACT_APP_BASE_URL,
  public_url: process.env.REACT_APP_PUBLIC_URL,
  // api
  api: {
    isExpired: 401,
    url: process.env.REACT_APP_API_URL,
    cloudImgPath: "https://pickauni-media.s3.amazonaws.com/",
  },
  mapApiKey: "AIzaSyBInycaDtYijb7243kjYQzthdIxD3ZCXUg",
  // others
  tenantId: 33,
  userId: 5514, // 6325
  cryptKey: "#cErLyPpKt#",
  // elements
  elements: {
    dp: {
      A: "MMMM DD, YYYY",
      B: "MMM DD, YYYY",
      C: "MMM DD, YYYY hh:mm A",
    },
    singleDp: {
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: "MMMM DD, YYYY",
      },
      autoApply: true,
      autoUpdateInput: false,
      isClearable: true,
    },
    toast: {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  },
  subject: {
    name_min: 3,
    name_max: 30,
  },
  statusCategory: [
    { label: "Application Review", value: "review", disabled: true },
    { label: "Course Suggestion", value: "course_suggestion", disabled: false },
    {
      label: "Missing Information",
      value: "missing_information",
      disabled: false,
    },
    { label: "Interview", value: "interview", disabled: false },
    { label: "Document Upload", value: "document_upload", disabled: false },
    { label: "Document Request", value: "document_request", disabled: false },
    { label: "Intake Differ", value: "intake_differ", disabled: false },
    { label: "Course Fee", value: "course_payment", disabled: false },
    { label: "Enrolled", value: "enrolled", disabled: false },
    { label: "Application Withdrawn", value: "cancelled", disabled: false },
    { label: "Application Rejected", value: "rejected", disabled: false },
  ],
  missingFields: [
    { label: "Personal - FirstName", value: "personal_firstName" },
    { label: "Personal - LastName", value: "personal_lastName" },
    { label: "Personal - Dob", value: "personal_dob" },
    { label: "Personal - Gender", value: "personal_gender" },
    { label: "Personal - Nationality", value: "personal_nationality" },
    { label: "Personal - FirstLanguage", value: "personal_firstLanguage" },
    { label: "Personal - MaritalStatus", value: "personal_maritalStatus" },
    {
      label: "Address - AddressWithRegion",
      value: "address_addressWithRegion",
    },
    { label: "Address - City", value: "address_city" },
    { label: "Address - Country", value: "address_country" },
    { label: "Address - Province", value: "address_province" },
    { label: "Address - PostalCode", value: "address_postalCode" },
    { label: "Address - Email", value: "address_email" },
    { label: "Address - Mobile", value: "address_mobile" },
    // { label: "Address - MobileCode", value: "address_mobileCode" },
    {
      label: "Education - CountryOfEducation",
      value: "education_countryOfEducation",
    },
    {
      label: "Education - HighLevelOfEducation",
      value: "education_highLevelOfEducation",
    },
    { label: "Education - Grade", value: "education_grade" },
    {
      label: "College - InstitutionCountry",
      value: "college_institutionCountry",
    },
    { label: "College - InstitutionName", value: "college_institutionName" },
    { label: "College - Qualification", value: "college_qualification" },
    { label: "College - Degree", value: "college_degree" },
    { label: "College - StartDate", value: "college_startDate" },
    { label: "College - EndDate", value: "college_endDate" },
    { label: "College - GraduationDate", value: "college_graduationDate" },
    { label: "College - GradingSystem", value: "college_gradingSystem" },
    { label: "College - College score", value: "college_score" },
    { label: "College - College Documents", value: "college_docs" },
    // { label: "Exam - Type", value: "exam_examType" },
    // { label: "Exam - DateOfIssue", value: "exam_dateOfIssue" },
    // { label: "Exam - TestScore", value: "exam_testScore" },
    // { label: "Exam - Listening", value: "exam_listening" },
    // { label: "Exam - Reading", value: "exam_reading" },
    // { label: "Exam - Writing", value: "exam_writing" },
    // { label: "Exam - Speaking", value: "exam_speaking" },
    // { label: "Exam - Exam Documents", value: "exam_docs" },
    {
      label: "Background - IsVisaBeforeECA",
      value: "background_isVisaBeforeECA",
    },
    {
      label: "Background - IsVisaRejectedECA",
      value: "background_isVisaRejectedECA",
    },
    {
      label: "Background - PassportNumber",
      value: "background_passportNumber",
    },
    { label: "Work Experience - Company Name", value: "workExp_companyName" },
    { label: "Work Experience - Designation", value: "workExp_designation" },
    { label: "Work Experience - JoinDate", value: "workExp_joinDate" },
    { label: "Work Experience - EndDate", value: "workExp_endDate" },
    {
      label: "Work Experience - Line Manager Name",
      value: "workExp_lineManagerName",
    },
    {
      label: "Work Experience - Line Manager Work Email",
      value: "workExp_lineManagerEmail",
    },
    {
      label: "Work Experience - Line Manager Designation",
      value: "workExp_lineManagerDesignation",
    },
    { label: "Work Experience - Company Email", value: "workExp_companyEmail" },
    {
      label: "Work Experience - Company Address",
      value: "workExp_companyAddress",
    },
    { label: "Work Experience - Documents", value: "workExp_docs" },
    {
      label: "Other - Documents",
      value: "modal_otherActivity",
    },
    {
      label: "Extra - Curricular Documents",
      value: "modal_extraCurricular",
    },
    // {
    //   label: "Visa Document",
    //   value: "modal_visaDocs",
    // },
    {
      label: "Passport Document",
      value: "modal_passportDocs",
    },
  ],
  missingStatus: [
    { value: "missing", label: "Missing" },
    { value: "under_review", label: "Under Review" },
    { value: "reviewed", label: "Reviewed" },
  ],
  interviewStatus: [
    { value: "Scheduled", label: "Scheduled", disabled: true },
    { value: "Attended", label: "Attended", disabled: false },
    { value: "Not Attended", label: "Not Attended", disabled: false },
    { value: "Cancelled", label: "Cancelled", disabled: true },
    { value: "Rescheduled", label: "Rescheduled", disabled: true },
    { value: "Confirmed", label: "Confirmed", disabled: true },
  ],
};
