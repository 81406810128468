import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

// includes
import CommonStudentView from "includes/studentView";
import ApplicationProcess from "includes/applicationProcess";
import { api, common, types } from "helpers";
import StatusBar from "elements/statusBar";

function View() {
  const { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState("process");
  const [result, setResult] = useState(null);
  const [key, setKey] = useState("status");

  // effect
  useEffect(() => {
    getInfo();
  }, []);

  // api
  const getInfo = () => {
    let data = {
      url: "application",
      method: "GET",
      query: `/${id}`,
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        setResult(res.data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center">
        <img
          src={common.loadImg("back-icn.svg")}
          className="ed-cp"
          alt=""
          onClick={() => navigate(-1)}
        />
        <h1 className="page-title ps-4">Application View</h1>
      </div>
      <div className="content-wrap">
        <div className="inner-content-wrap">
          {status !== "success" ? (
            <StatusBar status={status} />
          ) : (
            <Fragment>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="ed-tabs mb-3"
              >
                <Tab eventKey="status" title="Application Info">
                  <div className="et-vc-info et-view-border-bottom">
                    <ul className="et-vc-list row">
                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Application Id</p>
                          <p className="et-field-value">
                            {common.readable("string", result.refId)}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Submited At</p>
                          <p className="et-field-value">
                            {common.readable("dateTime", result.createdAt)}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Current Status</p>
                          <p className="et-field-value">
                            <span
                              className={`alert et-status et_status_${result.currentStatus}`}
                            >
                              {types.applicationStatus[result.currentStatus]}
                            </span>
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Course Name</p>
                          <p className="et-field-value">
                            {result.course?.name}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">University Name</p>
                          <p className="et-field-value">
                            {result.course?.university?.name}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Intake</p>
                          <p className="et-field-value">
                            {common.getMonthList()[result.month - 1].label}{" "}
                            {result.year}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Agent Details</p>
                          <p className="et-field-value">
                            {result?.profile?.agentId?.email || "-"}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Student Details</p>
                          <p className="et-field-value">
                            {result.profile?.firstName}{" "}
                            {result.profile?.lastName}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Sales Person</p>
                          <p className="et-field-value">
                            {result.salesPersonId?.email || "-"}
                          </p>
                        </div>
                      </li>

                      {result?.subAgentId && (
                        <li
                          className={`col-md-4 et-view-border-bottom et-view-border-right`}
                        >
                          <div className="et-list-inner">
                            <p className="et-field-name">
                              Application Verified
                            </p>
                            <p className="et-field-value">
                              {result?.isVerified ? "Yes" : "No"}
                            </p>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </Tab>
                <Tab eventKey="info" title="Profile Info">
                  <CommonStudentView
                    id={id}
                    formType="application"
                    formModule="all"
                  />
                </Tab>
                <Tab eventKey="view" title="Process">
                  <ApplicationProcess id={id} result={result} />
                </Tab>
              </Tabs>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}
export default View;
