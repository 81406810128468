import CssBaseline from "@mui/material/CssBaseline";
import { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
// includes
import { common, types } from "helpers";
import InnerHeader from "./innerHeader";

const InnerLayout = (props) => {
  // props
  const navigate = useNavigate();
  const pathname = common.getPath(useLocation().pathname);

  // state
  const [isAuth] = useState(common.checkAuth());
  const [open, setOpen] = useState(true);
  const [showSubIndex, setShowSubIndex] = useState(null);

  const handleDrawer = () => {
    setOpen(!open);
  };
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!isAuth) {
      localStorage.clear();
      navigate("/login");
    }
  }, [isAuth]);

  const toggleSubMenu = (index) => {
    setShowSubIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  // render
  return (
    isAuth && (
      <Fragment>
        <InnerHeader handleDrawer={handleDrawer} />
        <div className="admin-wrap">
          <CssBaseline />
          <aside
            className={!open ? "sidebar-nav-wrap toggle" : "sidebar-nav-wrap"}
          >
            <ul className="sb-nav">
              {types.menus.map(
                (i, k) =>
                  permission?.includes(i.roleKey) && (
                    <>
                      <li
                        key={k}
                        className={
                          pathname === i.menuKey ? "active" : "in-active"
                        }
                      >
                        {i?.subMenu ? (
                          <div
                            className="main-menu"
                            key={"k_" + k}
                            onClick={() => toggleSubMenu(i.menuKey)}
                          >
                            <img src={common.loadImg(i.icon)} alt={i.name} />{" "}
                            <span>{i.name}</span>
                          </div>
                        ) : (
                          <Link to={i.url}>
                            <div>
                              <img src={common.loadImg(i.icon)} alt={i.name} />{" "}
                              <span>{i.name}</span>
                            </div>
                          </Link>
                        )}
                      </li>
                      <div className="submenu-wrap">
                        {i?.subMenu &&
                          showSubIndex == i.menuKey &&
                          i?.subMenu.map((s, idx) => {
                            return (
                              permission?.includes(s.roleKey) && (
                                <li
                                  key={idx}
                                  className={
                                    pathname === s.menuKey
                                      ? "active sub-menu"
                                      : "in-active sub-menu "
                                  }
                                >
                                  <Link to={s.url}>
                                    <div>
                                      <img
                                        src={common.loadImg(s.icon)}
                                        className="sb-submenu-icn"
                                        alt={s.name}
                                      />
                                      <span>{s.name} </span>
                                    </div>
                                  </Link>
                                </li>
                              )
                            );
                          })}
                      </div>
                    </>
                  )
              )}
            </ul>
          </aside>
          <div
            className={
              !open
                ? "admin-content-wrap pl-toggle"
                : "admin-content-wrap pl-max"
            }
          >
            <Outlet {...props} />
          </div>
        </div>
      </Fragment>
    )
  );
};

export default InnerLayout;
