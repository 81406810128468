import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import MenuIcon from "@mui/icons-material/Menu";
// includes
import { common } from "helpers";
const InnerHeader = (props) => {
  // props
  const { handleDrawer } = props;
  const navigate = useNavigate();
  // functions
  const onLogout = (e) => {
    e.preventDefault();
    localStorage.clear();
    common.localRemove("adminAuthData");
    navigate("/login");
  };
  // render
  return (
    <header className="header">
      <div className="container-fluid">
        <Navbar className="header-nav-wrap" expand="lg" sticky="top">
          <Navbar.Brand href="#">
            <img
              src={common.cloudImg("media/us_tiny.png")}
              alt=""
              className="header-logo me-2"
            />
            <span>Admin</span>
          </Navbar.Brand>
          <button className="btn btn-toggle" onClick={handleDrawer}>
            <MenuIcon />
          </button>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="justify-content-end">
            <Nav activeKey="/home">
              <Nav.Item className="nav2-padding">
                <NavDropdown
                  align="end"
                  title={
                    <>
                      <div className="login-user-info">
                        <div className="d-flex align-items-center border-disable">
                          <div className="me-3">
                            <p className="header-username">
                              Welcome <br /> <strong>Admin</strong>
                            </p>
                          </div>

                          <img
                            src={common.loadImg("user-icon.svg")}
                            alt="userimage"
                            className="me-2"
                          />
                          <span>
                            <img src={common.loadImg("down-arrow.svg")}></img>
                          </span>
                        </div>
                      </div>
                    </>
                  }
                  id="collasible-nav-dropdown"
                  className="custom-arrow-dropdown"
                >
                  <NavDropdown.Item onClick={onLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
};

export default InnerHeader;
